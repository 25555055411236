$breakpoint-xl: 1536px
$breakpoint-lg: 1200px
$breakpoint-md: 900px
$breakpoint-sm: 600px

.section_bg
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../assets/wrapper.png')
    width: 100%
    height: 100%
    background-size: auto
    background-repeat: no-repeat
    color: #fff
    background-position: right 0%
    background-color: #000
    // position: absolute
    // top: 0
    .title
        font-family: 'LeagueSpartanBold'
        font-style: normal
        font-weight: 800
        font-size: 64px
        line-height: 110%
        color: #FFFFFF
        margin-bottom: 0
        margin-top: 0
        text-transform: capitalize
        span
            color: #E77133
    .info_text
        font-family: 'Montserrat'
        font-style: normal
        font-weight: 400
        font-size: 19px
        line-height: 160%
        color: rgba(255, 255, 255, 0.68)
        margin-bottom: 30px
        margin-top: 10px
    .appstore_rate
        display: flex
        margin-bottom: 40px
        &_num
            margin-left: 16px
            .rate
                font-family: 'Montserrat'
                font-style: normal
                font-weight: 800
                font-size: 22px
                line-height: 93%
                text-transform: uppercase
                color: #FFFFFF
            p
                font-family: 'Montserrat'
                font-style: normal
                font-weight: 500
                font-size: 14px
                line-height: 93%
                text-transform: capitalize
                color: #FFFFFF
                margin: 0

.section_app_info
    background-color: #000
    margin-bottom: -5px
    .title
        font-family: 'LeagueSpartanBold'
        font-style: normal
        font-weight: 800
        font-size: 58px
        line-height: 110%
        color: #FFFFFF
        margin-bottom: 0
        margin-top: 0
        text-transform: capitalize
        span
            color: #E77133
    .info_text
        font-family: 'Montserrat'
        font-style: normal
        font-weight: 400
        font-size: 19px
        line-height: 160%
        color: rgba(255, 255, 255, 0.68)
        margin-bottom: 30px
        margin-top: 20px

.section_improve
    // background: linear-gradient(to right bottom, #000 50%, #E9E8E5 0)
    overflow: hidden
    background-size: 110%
    background-repeat: no-repeat
    position: relative
    background-image: url('../assets/line.svg')
    width: 100%
    &::before
        content: ''
        position: absolute
        background-color: #E9E8E5
        width: 100%
        height: 100%
        z-index: -1
.section_blocks
    background-color: #E9E8E5

.block_right
    background: #FFFFFF
    margin-bottom: 40px
    box-shadow: 0px 15px 45px -15px rgba(0, 0, 0, 0.15)
    border-radius: 10px
    &_info
        padding: 60px
        h4
            font-family: 'LeagueSpartanBold'
            font-weight: 800
            font-size: 20px
            line-height: 110%
            text-transform: uppercase
            color: #E77133
            margin-top: 0
            margin-bottom: 5px
        .title
            font-family: 'LeagueSpartanBold'
            font-style: normal
            font-weight: 800
            font-size: 50px
            line-height: 110%
            text-transform: capitalize
            color: #343A40
            margin-top: 0
            margin-bottom: 20px
        .info_text
            font-family: 'Montserrat'
            font-weight: 400
            font-size: 17px
            line-height: 160%
            color: #252835
            margin: 0 auto 20px
    &_img
        border-radius: 0 10px 10px 0px
        height: 100%
        width: 100%
    .block_left_img
        border-radius: 10px 0 0 10px
        height: 100%
        width: 100%

.section_train
    background-color: #E9E8E5
    padding-top: 80px
    padding-bottom: 85px
    margin-bottom: -5px
    .title
        font-family: 'LeagueSpartanBold'
        font-style: normal
        font-weight: 800
        font-size: 58px
        line-height: 110%
        color: #222222
        margin: 0
    .sub_title
        font-family: 'Montserrat'
        font-weight: 400
        font-size: 20px
        line-height: 160%
        color: #252835
        margin: 0 0 40px
    .primary_button
        margin: auto
    .title_3
        margin-top: 60px
        font-family: 'LeagueSpartanBold'
        font-weight: 800
        font-size: 24px
        line-height: 110%
        text-align: center
        color: #222222
        margin-bottom: 0
    .card
        padding: 30px
        text-align: center
        margin-top: 30px
        &_title
            font-family: 'Montserrat'
            font-style: normal
            font-weight: 700
            font-size: 18px
            line-height: 160%
            color: #222222
            margin-top: 20px
            margin-bottom: 5px
        &_info
            font-family: 'Montserrat'
            font-style: normal
            font-weight: 400
            font-size: 16px
            line-height: 160%
            color: #252835
            margin: 0
.section_promo
    overflow: hidden
    background-size: 110%
    background-repeat: no-repeat
    position: relative
    background-image: url('../assets/line2.svg')
    width: 100%
    padding-bottom: 80px
    height: 100%
    &::before
        content: ''
        position: absolute
        background-color: #000
        width: 100%
        height: 100%
        z-index: -1
    .title
        font-family: 'LeagueSpartanBold'
        font-style: normal
        font-weight: 800
        font-size: 58px
        line-height: 110%
        color: #FFFFFF
        margin-bottom: 0
        margin-top: 0
.section_exp
    background-color: #000
    width: 100%
    heigt: 100%
    padding-top: 80px
    color: #fff
    margin-bottom: -5px
    position: relative

    .title
        font-family: 'LeagueSpartanBold'
        font-style: normal
        font-weight: 800
        font-size: 58px
        line-height: 100%
        color: #FFFFFF
        margin-top: 0
        margin-bottom: 30px
        span
            color: #E77133
    .sub_title
        font-family: 'LeagueSpartanBold'
        font-style: normal
        font-weight: 800
        font-size: 24px
        line-height: 110%
        text-transform: uppercase
        color: #FFFFFF
        margin: 0
    ul
        list-style: none
        padding-left: 0
        margin-top: 15px
        margin-bottom: 30px
        li
            display: flex
            align-items: center
            padding: 15px 0
            border-bottom: 1px solid #222
            div
                margin-left: 20px
                h5
                    font-family: 'Montserrat'
                    font-weight: 700
                    font-size: 16px
                    line-height: 130%
                    color: #FFFFFF
                    margin: 0
                p
                    margin: 0
                    font-family: 'Montserrat'
                    font-style: normal
                    font-weight: 400
                    font-size: 16px
                    line-height: 130%
                    color: rgba(255, 255, 255, 0.68)
    .images_block
        img
            border-radius: 5px
            margin-bottom: 32px
            z-index: -1
    .footer_line
        min-width: 100%
        height: 100%
        margin-top: -200px
        img
            min-width: 100%
            height: 100%
    .orang_bdy_sec_wrapper
        z-index: 0
        background: #E77133
        position: absolute
        width: 100%
        height: 28%
        bottom: 0
        transform: skewY(-8deg)
        transform-origin: right bottom
        // margin-top: -51px
.footer
    background: #E77133
    position: relative
    .title
        margin-top: 0
        color: #fff
        font-family: 'LeagueSpartanBold'
        font-style: normal
        font-weight: 800
        font-size: 60px
        line-height: 110%
        margin-bottom: 20px
    .sub_title
        font-family: 'Montserrat'
        font-style: normal
        font-weight: 400
        font-size: 20px
        line-height: 160%
        text-align: center
        color: #FFFFFF
        margin-top: 0
        margin-bottom: 20px
    .primary_button
        margin: 0 auto
        background-color: #000 !important
        box-shadow: none !important
        span
            color: rgba(255, 255, 255, 0.5) !important
    .sub_footer
        margin-top: 80px
        padding: 20px 0px
        border-top: 1px solid #CB632D
        text-transform: uppercase
        font-family: 'Montserrat'
        color: rgba(0, 0, 0, 0.4)
        h6
            font-weight: 600
            font-size: 11px
            line-height: 120%
            margin: 0
        ul
            padding: 0
            list-style: none
            display: flex
            justify-content: flex-end
            li
                font-weight: 600
                font-size: 11px
                line-height: 120%
                margin-right: 20px
                &:last-child
                    margin-right: 0
                a
                    text-decoration: none
                    color: inherit

.primary_button
    font-family: 'MontserratBold' !important
    padding: 18px 40px !important
    background: #3198EE !important
    box-shadow: 0px 10px 60px rgba(49, 152, 238, 0.5) !important
    border-radius: 50px !important
    color: #fff !important
    display: flex !important
    flex-direction: column !important
    font-size: 22px !important
    font-weight: 800 !important
    span
        font-family: 'Montserrat' !important
        font-style: normal !important
        font-weight: 600 !important
        font-size: 11px !important
        line-height: 100% !important
        letter-spacing: 0.1em !important
        text-transform: uppercase !important
        color: rgba(0, 0, 0, 0.5) !important
        display: block

.link_button
    padding: 10px 0px !important
    color: #3198EE !important
    border-bottom: 3px solid #3198EE !important
    border-radius: 0px !important
    font-family: 'MontserratBold' !important
    font-weight: 800 !important
    font-size: 17px !important
    line-height: 93% !important
.react_player
    border-radius: 10px
    overflow: hidden
    // filter: drop-shadow(15px 15px 45px rgba(0, 0, 0, 0.35))
    video
        object-fit: cover
        // filter: drop-shadow(15px 15px 45px rgba(0, 0, 0, 0.35))

@media (max-width: 1100px)
    .section_app_info
        padding-bottom: 32px
    .section_exp
        .orang_bdy_sec_wrapper
            height: 17%
    .block_right
        &_info
            padding: 40px
            .title
                font-size: 40px
            .info_text
                font-size: 16px

@media (max-width: $breakpoint-md)
    .section_app_info
        padding-bottom: 32px
    .section_exp
        .orang_bdy_sec_wrapper
            height: 7%
    .block_right
        &_info
            padding: 60px
            .title
                font-size: 40px
            .info_text
                font-size: 16px
        &_img
            border-radius: 0 0px 10px 10px
        .block_left_img
            border-radius: 10px 10px 0px 0px
    .section_bg
        // justify-content: center
        // text-align: center
        // .primary_button
        //     margin: auto
    .video_player_wrapper
        display: flex
        justify-content: center
    .section_train
        .card
            padding: 12px
@media (max-width: $breakpoint-sm)
    .block_right
        h4
            font-size: 18px
        &_info
            padding: 40px
            .title
                font-size: 30px
    .section_bg
        .title
            font-size: 50px
        .info_text
            font-size: 17px
    .primary_button
        font-size: 18px!important
    .section_app_info
        .title
            font-size: 45px
        .info_text
            font-size: 17px
    .section_train
        .title
            font-size: 40px
        .sub_title
            font-size: 18px
    .section_train
        .card
            margin-top: 16px
    .section_promo
        .title
            font-size: 45px
            margin-top: 60px
    .section_exp
        .title
            font-size: 45px
        .sub_title
            font-size: 18px
    .footer
        .title
            font-size: 45px
        .sub_title
            font-size: 18px
        .sub_footer
            text-align: center
            ul
                justify-content: center

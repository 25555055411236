$primary-color: #E77133

.orange_button
    padding: 10px 20px
    background: linear-gradient(0deg, $primary-color,$primary-color), $primary-color
    border-radius: 50px
    text-transform: uppercase
    color: #fff
    font-weight: 800
    font-size: 15px
    line-height: 93%
    text-decoration: none
    font-family: 'Montserrat'
.toolbar
    display: flex
    justify-content: space-between
    align-items: center
    padding: 0 !important

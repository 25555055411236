body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Montserrat';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: "LeagueSpartan";
  src: url("./assets/fonts/League_Spartan/static/LeagueSpartan-Regular.ttf");
  src: url("./assets/fonts/League_Spartan/static/LeagueSpartan-Regular.woff") format("woff");
  src: url("./assets/fonts/League_Spartan/static/LeagueSpartan-Regular.woff2") format("woff2");
  font-weight: normal;
}
@font-face {
  font-family: "LeagueSpartanBold";
  src: url("./assets/fonts/League_Spartan/static/LeagueSpartan-Bold.ttf");
  src: url("./assets/fonts/League_Spartan/static/LeagueSpartan-Bold.woff") format("woff");
  src: url("./assets/fonts/League_Spartan/static/LeagueSpartan-Bold.woff2") format("woff2");
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat/static/Montserrat-Regular.ttf");
  src: url("./assets/fonts/Montserrat/static/Montserrat-Regular.woff") format("woff");
  src: url("./assets/fonts/Montserrat/static/Montserrat-Regular.woff2") format("woff2");
  font-weight: normal;
}
@font-face {
  font-family: "MontserratBold";
  src: url("./assets/fonts/Montserrat/static/Montserrat-Bold.ttf");
  src: url("./assets/fonts/Montserrat/static/Montserrat-Bold.woff") format("woff");
  src: url("./assets/fonts/Montserrat/static/Montserrat-Bold.woff2") format("woff2");
  font-weight: normal;
}

img {
  max-width: 100%;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #ffffff;

}

::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #ffffff;

}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #dadada;

}

::-moz-scrollbar {
  width: 4px;
  background-color: #ffffff;
}

::-moz-scrollbar-track {
  border-radius: 8px;
  background-color: #ffffff;
}

::-moz-scrollbar-thumb {
  border-radius: 8px;
  background-color: #dadada;
}